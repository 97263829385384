import React, { useState } from 'react'
import { CentroAtencionIcon } from '../assets/icons/CentroAtencionIcon'
import { CheckIcon } from '../assets/icons/CheckIcon'
import Star from '../assets/icons/Star'
import axiosInstance from '../axios'
import { actionTypes } from '../redux/constants'
import { fnSetStorage } from '../redux/functions'
import { useStateValue } from '../redux/StateProvider'
import { handleMesssageError } from '../utils/handleError'
import { textLang } from '../utils/lang'
import { Alert } from './Alert'

export const Calificacion = ({chatId}) => {
  const [star, setStar] = useState(0)
  const [error, setError] = useState(null)
  const [{lang, cerrado}, dispatch] = useStateValue();

  const handleClick = num => {
    setStar(num)
    const data = {
      firebaseId: chatId,
      calificacion: num
    }
    axiosInstance.post('/chat/calificacion', data)
    .then(() => {
      fnSetStorage(dispatch, 1, actionTypes.SET_CERRADO)
    })
    .catch(e => setError(e))
  }

  return (
    <>
    <Alert message={textLang(lang, 'message_cerrado')} />
    <div className="ssa__calificacion">
      {cerrado ? <CheckIcon /> : <CentroAtencionIcon color="#5E46FF"/>}
      <h2>{cerrado ? textLang(lang, 'calificacion_gracias') : textLang(lang, 'calificacion')}</h2>
      <p>{cerrado ? '' : textLang(lang, 'calificacion_texto')}</p>
      <div className="ssa__calificacion--stars">
        {[1,2,3,4,5].map(item => (
          <Star key={item} activo={item <= star} onClick={() => handleClick(item)}/>
        ))}
      </div>
      {error && <span style={{color: 'red', fontSize: '12px'}}>{handleMesssageError(error)}</span>}
    </div>
    </>
  )
}
