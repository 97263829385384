export const config = {
  URL_UPLOADS: '' || process.env.REACT_APP_URL_IMAGES,
  api: {
    division: '/division',
    chat: '/chat',
    contacto: 'chat-contacto',
    configuracion: '/configuracion',
    admin: '/admin',
    horario: '/horario',
    tiporol: '/tipo-rol',
    tiposemana: '/tipo-semana'
  }
}