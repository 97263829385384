export const LANG_ES = {
  nombre: 'Nombre',
  telefono: 'Teléfono',
  producto_interes: 'Producto de interés',
  como_ayudamos: '¿Cómo te ayudamos?',
  email: 'Correo',
  ciudad: 'Ciudad',
  estado: 'Estado',
  pais: 'País',
  calificacion: 'Califica tu experiencia:',
  calificacion_texto: '¿Cómo ha sido tu experiencia?',
  calificacion_gracias: '¡Gracias!',
  datos_recibidos: 'Datos recibidos',
  datos_recibidos_content: 'Tu información ha sido enviada correctamente',
  fantastico: 'FANTÁSTICO',
  fantastico_texto: 'Uno de nuestros especialistas se pondrá en contacto contigo.',
  terminos: 'Al enviar tus datos aceptas los términos y condiciones de uso de datos',
  consultar: 'Puedes consultar nuestro',
  aviso: 'Aviso de Privacidad',
  campos: '*Campos obligatorios',
  message_ocupado: 'En este momento todos nuestros agentes están ocupados, espera un momento. Mientras tanto, puedes ir apoyándonos con tus datos para personalizar aún más la atención.',
  message_cola1: 'Tu chat ha entrado a la cola, faltan',
  message_cola2: 'turnos para atenderte',
  message_cola3: 'turno para atenderte',
  message_cerrado: 'El chat se ha cerrado',
  placeholder_nombre: 'Escribe tu nombre',
  placeholder_email: 'Escribe tu email',
  placeholder_telefono: 'Escribe tu número a 10 dígitos',
  placeholder_producto: 'Escribe tu  producto de interés',
  placeholder_ayudamos: 'Escribe tu duda',
  placeholder_message: 'Escribe un mensaje',
  placeholder_ciudad: 'Escribe una ciudad',
  placeholder_estado: 'Escribe un estado',
  placeholder_pais: 'Escribe un país',
  error_completa: 'Completa el formulario para enviar',
  error_correo: 'Correo no válido',
  error_telefono: 'Sólo números',
  button_duda: 'Tengo otra duda',
  button_enviar: 'Enviar',
  button_terminar: 'Terminar chat'
}