import React from 'react'

export const AttachIcon = (props) => {
  return (
    <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.375 4.5v8.625a3 3 0 1 1-6 0V3.75a1.876 1.876 0 0 1 3.75 0v7.875c0 .412-.338.75-.75.75a.752.752 0 0 1-.75-.75V4.5H7.5v7.125a1.876 1.876 0 0 0 3.75 0V3.75a3 3 0 1 0-6 0v9.375a4.123 4.123 0 0 0 4.125 4.125 4.123 4.123 0 0 0 4.125-4.125V4.5h-1.125Z"
      fill="#392A6F"
    />
  </svg>
  )
}
