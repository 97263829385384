import { useInfiniteQuery, useQuery, useMutation } from 'react-query';
import axiosInstance from '../axios'

const getAll = async ({url, params}) => {
  return (await axiosInstance.get(url, { params })).data;
}

const getOne = async ({url, id}) => {
  return (await axiosInstance.get(`${url}/${id}`)).data
}

const getWithoutOne = async ({url}) => {
  return (await axiosInstance.post(url)).data
}

export const getDelete = ({url, id}) => {
  return axiosInstance.delete(`${url}/${id}`)
}


export function useAll ({nameQuery, url, params = {}, onSuccess = () => {}}) {
  return useQuery(nameQuery, () => getAll({url, params}), {
    retry: false,
    onSuccess: onSuccess
  })
}

export function useOne ({nameQuery, url, id, onSuccess = () => {}}) {
  return useQuery(nameQuery, () => getOne({url, id}), {
    retry: false,
    onSuccess: onSuccess
  })
}

export function useWithoutOne ({nameQuery, url, onSuccess = () => {}}) {
  return useQuery(nameQuery, () => getWithoutOne({url}), {
    retry: false,
    onSuccess: onSuccess
  })
}

export function useInfiniteAll ({nameQuery, url, params}) {
  return useInfiniteQuery(
    nameQuery, 
    (paramsQuery) =>  getAll({url, params: {...params, page: paramsQuery.pageParam ? paramsQuery.pageParam + 1 : null }}), 
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      getFetchMore: lastGroup => lastGroup?.length > 14,
      getPreviousPageParam: firstPage => firstPage.links?.prev ?? false,
      getNextPageParam: lastPage => lastPage.links?.next ?? false
    })
}

export function useUpdate({url, id, onSuccess = () => {}}) {
  return useMutation(data => axiosInstance.patch(`${url}/${id}`, data), {
    onSuccess: onSuccess
  })
}

export function useAdd({url, onSuccess = () => {}}) {
  return useMutation(data => axiosInstance.post(url, data), {
    onSuccess: onSuccess
  })
}
