import React, { useState } from 'react'
import Message from '../Components/Message'
import { useAdd } from '../hooks/useApi'
import { useStateValue } from '../redux/StateProvider'
import {config as configuracion} from '../config'
import { textLang } from '../utils/lang'
import { Alert } from '../Components/Alert'
import { Complete } from '../Components/Complete'
import { addMessage } from '../db/dbFirebase'
import { useForm } from 'react-hook-form'

export default function ChatEnespera({ enespera }) {
  const [enviado, setEnviado] = useState(false)
  const { register, reset, setValue, formState: { errors }, handleSubmit } = useForm();
  const [{division,lang,chatId, config }] = useStateValue()

  const contactoMutation = useAdd({url: configuracion.api.contacto, onSuccess: async () => {
    await addMessage({chatId, solicitar: 4})
    setEnviado(true)
    reset()
  }})

  const onSubmit = (data) => contactoMutation.mutate({...data, division_id: division, firebaseId: chatId})

  const estado = register("estado", { required: config?.formulario_estado})
  const pais = register("pais", { required: config?.formulario_estado})
  const telefono = register("telefono", { required: config?.formulario_telefono })

  const handleChange = ({target}) => {
    const {name, value} = target

    const regMatch = name === 'telefono' ? /^[0-9]*$/.test(value)  : /^[a-zA-Z]*$/.test(value)
    if (regMatch) {
      setValue(name, value, { shouldValidate: true })
    } else {
      setValue(name, value.substring(0, value.length - 1), { shouldValidate: true })
    }
  }

  return (
    <>
      <Alert message={<MessageEspera enespera={enespera} lang={lang}/>}/>
      <Message 
        message={textLang(lang, 'message_ocupado')}
        />
      {enviado ? (
        <Complete 
          title={textLang(lang, 'fantastico')}
          content={textLang(lang, 'fantastico_texto')}
        />
      ) : (
        <div className="ssa__formulario">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ssa__field">
              <label>
                {textLang(lang, 'nombre')}
                {config?.formulario_nombre ? '*' : ''}
              </label>
              <input 
                className={`ssa__input ${errors.nombre ? 'ssa__input--error' : ''}`}
                type="text"  
                placeholder={textLang(lang, 'placeholder_nombre')} 
                {...register("nombre", { required: config?.formulario_nombre })}
                />
            </div>
            <div className="ssa__field">
              <label>
                {textLang(lang, 'email')}
                {config?.formulario_correo ? '*' : ''}
              </label>
              <input 
                className={`ssa__input ${errors.correo ? 'ssa__input--error' : ''}`}
                type="email" 
                placeholder={textLang(lang, 'placeholder_email')}
                {...register("correo", { required: config?.formulario_correo, pattern: /^\S+@\S+$/i })}
              />
              {errors.correo && <span style={{color: 'red', fontSize: '12px'}}>{textLang(lang, 'error_correo')}</span>}
            </div>
            <div className="ssa__field">
              <label>
                {textLang(lang, 'telefono')}
                {config?.formulario_telefono ? '*' : ''}
              </label>
              <input 
                className={`ssa__input ${errors.telefono ? 'ssa__input--error' : ''}`}
                type="text" 
                placeholder={textLang(lang, 'placeholder_telefono')}
                onChange={(e) => {
                  handleChange(e); // your method
                }}
                name="telefono"
                onBlur={telefono.onBlur}
                ref={telefono.ref}
                />
              {errors.telefono && <span style={{color: 'red', fontSize: '12px'}}>{textLang(lang, 'error_telefono')}</span>}
            </div>
            <div className="ssa__field">
              <label>
                {textLang(lang, 'producto_interes')}
                {config?.formulario_producto ? '*' : ''}
              </label>
              <input 
                className={`ssa__input ${errors.producto_interes ? 'ssa__input--error' : ''}`}
                type="text" 
                placeholder={textLang(lang, 'placeholder_producto')}
                {...register("producto_interes", { required: config?.formulario_producto })}
                />
            </div>
            <div className="ssa__field">
              <label>
                {textLang(lang, 'como_ayudamos')}
                {config?.formulario_ayudamos ? '*' : ''}
              </label>
              <textarea 
                className={`ssa__input ${errors.como_ayudamos ? 'ssa__input--error' : ''}`}
                placeholder={textLang(lang, 'placeholder_ayudamos')} 
                {...register("como_ayudamos", { required: config?.formulario_ayudamos })}></textarea>
            </div>
            <div className="ssa__field">
              <label>
                {textLang(lang, 'pais')}
                {config?.formulario_pais ? '*' : ''}
              </label>
              <input 
                className={`ssa__input ${errors.pais ? 'ssa__input--error' : ''}`}
                type="text" 
                placeholder={textLang(lang, 'placeholder_pais')}
                onChange={(e) => {
                  handleChange(e); // your method
                }}
                name="pais"
                onBlur={pais.onBlur}
                ref={pais.ref}
                />
            </div>
            <div className="ssa__field">
              <label>
                {textLang(lang, 'estado')}
                {config?.formulario_estado ? '*' : ''}
              </label>
              <input 
                className={`ssa__input ${errors.estado ? 'ssa__input--error' : ''}`}
                type="text" 
                placeholder={textLang(lang, 'placeholder_estado')}
                onChange={(e) => {
                  handleChange(e); // your method
                }}
                name="estado"
                onBlur={estado.onBlur}
                ref={estado.ref}
                />
            </div>
            {contactoMutation.isError && <span style={{color: 'red', fontSize: '12px'}}>{textLang(lang, 'error_completa')}</span>}
            <p className="ssa__terminos">{textLang(lang, 'campos')}</p>
            <p className="ssa__terminos ssa__terminos--notop">{textLang(lang, 'terminos')}</p>
            <p className="ssa__terminos ssa__terminos--notop">
              {textLang(lang, 'consultar')} <a className="ssacolor-morado" href="https://sanfersaludanimal.com/assets/images/aviso_privacidad.doc.pdf" target="_blank" rel="noreferrer">{textLang(lang, 'aviso')}</a>.
            </p>
            <div className="ssa__field ssa__field--button">
              <button disabled={contactoMutation.isLoading} type="submit">{textLang(lang, 'button_enviar')}</button>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

const MessageEspera = ({enespera, lang}) => {
  return (
    <>
    {textLang(lang, 'message_cola1')} <b>{enespera}</b> {enespera === 1 ? textLang(lang, 'message_cola3') :  textLang(lang, 'message_cola2')} 
    </>
  )
}