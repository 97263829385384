import { actionTypes } from "../redux/constants";
import { callDispatch } from "../redux/functions";

export const clearStorage = dispatch => {
  localStorage.removeItem(actionTypes.SET_STEP);
  localStorage.removeItem(actionTypes.SET_CHAT);
  localStorage.removeItem(actionTypes.SET_DIVISION);
  localStorage.removeItem(actionTypes.SET_CERRADO);

  
  callDispatch(dispatch, 1, actionTypes.SET_STEP);
  callDispatch(dispatch, 0, actionTypes.SET_DIVISION);
  callDispatch(dispatch, null, actionTypes.SET_CHAT);
  callDispatch(dispatch, 0, actionTypes.SET_CERRADO);
  callDispatch(dispatch, false, actionTypes.SET_FINALIZO);
  callDispatch(dispatch, null, actionTypes.SET_INFO)
}