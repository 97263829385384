
import React, { useState } from 'react'
import DivisionItem from '../Components/DivisionItem'
import Loading from '../Components/Loading'
import Message from "../Components/Message"
import { config as configuracion } from '../config'
import { useAll } from '../hooks/useApi'
import { useStateValue } from '../redux/StateProvider'

export default function ChatDivision({config}) {
  const [{lang}] = useStateValue()
  const [divisions, setDivisions] = useState([])
  const {status, error} = useAll({nameQuery: "divisions", url: configuracion.api.division, onSuccess: ({data}) => onSuccessDivisions(data)})

  const onSuccessDivisions = data => {
    setDivisions(data.filter(f => [1, 2, 3, 9].includes(f.id)))
  }

  if (status === 'loading') return <Loading />;
  if (status === 'error') return <span>Error: {error.message}</span>
  
  return (
    <>
      <Message 
        message={lang === 'es' ? config?.texto_bienvenida : config?.texto_bienvenida_ingles}
      />
      <div className="ssachat__divisiones">
        <ul>
          {divisions.map(item => 
             <DivisionItem key={item.id}
               {...item}
             />
          )}
        </ul>
      </div>
    </>
  )
}
