export const actionTypes = {
  SET_STEP: 'SET_STEP',
  SET_CHAT: 'SET_CHAT',
  SET_ENESPERA: 'SET_ENESPERA',
  SET_LOADING: 'SET_LOADING',
  SET_ESCRIBIR_ADMIN: 'SET_ESCRIBIR_ADMIN',
  SET_CONFIG: 'SET_CONFIG',
  SET_FINALIZO: 'SET_FINALIZO',
  SET_CERRADO: 'SET_CERRADO',
  SET_DIVISION: 'SET_DIVISION',
  SET_LANG: 'SET_LANG',
  SET_INFO: 'SET_INFO',
  SET_SOLICITAR: 'SET_SOLICITAR',
  SET_HORARIO: 'SET_HORARIO',
  SET_TRANSFERIR: 'SET_TRANSFERIR',
  SET_TERMINAR_MODAL: 'SET_TERMINAR_MODAL'
}