import React from 'react'
import worldIcon from '../assets/icons/world.svg'
import centroIcon from '../assets/icons/centro_atencion.svg'
import { useStateValue } from '../redux/StateProvider'
import { callDispatch } from '../redux/functions'
import { actionTypes } from '../redux/constants'
import { getURLImage } from '../db/initialDB'

export const ChatHeader = ({ config, onClick, onClickClose, loadingImage }) => {
  const [{lang, info, horario}, dispatch] = useStateValue()

  const handleClick = () => {
    const value = lang === 'es' ? 'en' : 'es'
    callDispatch(dispatch, value, actionTypes.SET_LANG)
  }

  const getTexto1 = () => {
    if (lang === 'es') {
      return horario ? config?.texto_linea1 : config?.texto_linea1_fuera
    } else {
      return horario ? config?.texto_linea1_ingles : config?.texto_linea1_fuera_ingles
    }
  }

  const getTexto2 = () => {
    if (lang === 'es') {
      return horario ? config?.texto_linea2 : config?.texto_linea2_fuera
    } else {
      return horario ? config?.texto_linea2_ingles : config?.texto_linea2_fuera_ingles
    }
    
  }
 
  return (
    <div className="ssachat__header" onClick={onClick}>
      <ChatHeaderImage imagen={info?.imagen}/>
      <div className="ssachat__header--text">
        <p>{info === null ? getTexto1() : info.nombre}</p>
        <p>{info === null ? getTexto2() : lang === 'es' ? info.cargo : info.cargo_ingles}</p>
      </div>
      <div className="ssachat__header--actions">
        <div className="ssachat__header--close" onClick={onClickClose}></div>
        <div className="ssachat__header--lang ssachat__pointer" onClick={handleClick}>
          <img src={worldIcon} alt="Icono lenguage" />
          <p>{lang === 'es' ? 'MX' : 'US'}</p>
        </div>
      </div>
      {loadingImage && <div className='ssachat__header--loading'></div>}
    </div>
  )
}

const ChatHeaderImage = ({imagen = null}) => {
  if (imagen) {
    return (
      <div className="ssachat__header--image ssachatuser-image">
        <img src={getURLImage('admin', imagen)} alt="Centro de atención" />
      </div>
    )
  } else {
    return (
      <div className="ssachat__header--image">
        <img src={centroIcon} alt="Centro de atención" />
      </div>
    )
  }
  
}
