export const LANG_EN = {
  nombre: 'Name',
  telefono: 'Phone number',
  producto_interes: 'Product of interest',
  como_ayudamos: 'How do we help you?',
  email: 'Email',
  ciudad: 'City',
  estado: 'State',
  pais: 'Country',
  calificacion: 'Rate your experience:',
  calificacion_texto: 'How was your experience?',
  calificacion_gracias: 'Thank you!',
  datos_recibidos: 'Data received',
  datos_recibidos_content: 'Your information has been sent correctly',
  fantastic: 'FANTASTIC',
  fantastico_texto: 'One of our specialists will contact you.',
  terminos: 'By submitting your data you accept the terms and conditions of data use',
  consultar: 'You can check our',
  aviso: 'Notice of Privacy',
  campos: '*Required fields',
  message_ocupado: 'Right now all of our agents are busy, wait a minute. In the meantime, you can support us with your data to further personalize the service.',
  message_cola1: 'Your chat has entered the queue, they are missing',
  message_cola2: 'turns to assist you',
  message_cola3: 'turn to assist you',
  message_cerrado: 'The chat has been closed',
  placeholder_nombre: 'Write your name',
  placeholder_email: 'Write your email',
  placeholder_telefono: 'Write your phone number in 10 digits',
  placeholder_producto: 'Write your product of interest',
  placeholder_ayudamos: 'Write your question',
  placeholder_message: 'Write your message',
  placeholder_ciudad: 'Write your city',
  placeholder_estado: 'Write your state',
  placeholder_pais: 'Write your country',
  error_completa: 'Complete the form to submit',
  error_correo: 'Invalid email',
  error_telefono: 'Only numbers',
  button_duda: 'I have another doubt',
  button_enviar: 'Send',
  button_terminar: 'Close chat'
}