function Logro(props) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path fill="url(#prefix__paint0_linear)" d="M7.092 5h15v14h-15z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.819 29.063V3.75c0-.995.392-1.948 1.091-2.652A3.716 3.716 0 017.546 0h14.908c.989 0 1.937.395 2.636 1.098A3.762 3.762 0 0126.18 3.75v25.313a.942.942 0 01-.456.806.928.928 0 01-.923.017L15 24.504l-9.802 5.382a.926.926 0 01-1.257-.36.942.942 0 01-.122-.463zm11.48-21.375A.334.334 0 0015 7.5a.33.33 0 00-.298.188l-1.182 2.409a.333.333 0 01-.25.184l-2.646.386a.331.331 0 00-.272.41c.015.06.046.115.09.158l1.912 1.877a.335.335 0 01.095.296l-.45 2.651a.335.335 0 00.304.388.33.33 0 00.177-.037l2.367-1.252a.33.33 0 01.308 0l2.366 1.252a.331.331 0 00.48-.35l-.452-2.654a.336.336 0 01.093-.296l1.916-1.877a.334.334 0 00-.182-.568l-2.647-.386a.331.331 0 01-.25-.184L15.3 7.687z"
          fill="url(#prefix__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={14.623}
          y1={19}
          x2={13.972}
          y2={5.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB572" />
          <stop offset={1} stopColor="#FDD162" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={15}
          y1={0}
          x2={15}
          y2={30.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D96FF" />
          <stop offset={1} stopColor="#5E46FF" />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(.092)" d="M0 0h29.817v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logro