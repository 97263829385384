import { getStorage } from '../db/initialDB'
import {actionTypes} from './constants'

export const initialState = {
  step: parseInt(getStorage(actionTypes.SET_STEP)) || 1,
  chatId: null || getStorage(actionTypes.SET_CHAT),
  enespera: true,
  division: parseInt(getStorage(actionTypes.SET_DIVISION)) || 0,
  loading: false,
  escribir_admin: false,
  config: null,
  cerrado: parseInt(getStorage(actionTypes.SET_CERRADO)) || 0,
  finalizo: false,
  lang: 'es',
  info: null,
  solicitar: false,
  horario: false,
  transferir: false,
  modalTerminar: false
}

const reducer = (state, action) => {
  switch(action.type) {
    case actionTypes.SET_STEP:
      return {
        ...state,
        step: action.payload
      }
    case actionTypes.SET_CHAT:
      return {
        ...state,
        chatId: action.payload
      }
    case actionTypes.SET_ENESPERA:
      return {
        ...state,
        enespera: action.payload
      }
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case actionTypes.SET_CONFIG:
      return {
        ...state,
        config: action.payload
      }
    case actionTypes.SET_FINALIZO:
      return {
        ...state,
        finalizo: action.payload
      }
    case actionTypes.SET_CERRADO:
      return {
        ...state,
        cerrado: action.payload
      }
    case actionTypes.SET_DIVISION:
      return {
        ...state,
        division: action.payload
      }
    case actionTypes.SET_LANG:
      return {
        ...state,
        lang: action.payload
      }
    case actionTypes.SET_INFO:
      return {
        ...state,
        info: action.payload
      }
    case actionTypes.SET_SOLICITAR:
      return {
        ...state,
        solicitar: action.payload
      }
    case actionTypes.SET_HORARIO:
      return {
        ...state,
        horario: action.payload
      }
    case actionTypes.SET_TRANSFERIR:
      return {
        ...state,
        transferir: action.payload
      }
    case actionTypes.SET_TERMINAR_MODAL:
        return {
          ...state,
          modalTerminar: action.payload
        }
    default:
      return state
  }
}

export default reducer;