import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import reducer, { initialState } from './redux';
import { StateProvider } from './redux/StateProvider';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('ssawidget')
);
