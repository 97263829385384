import React from 'react'
import Logro from '../assets/icons/Logro'

export const Complete = ({ title, content, contentComponent, button = null, onClick = () => {}, image = <Logro /> }) => {
  return (
    <div className="ssa__fantastico">
      {image}
      <h3>{title}</h3>
      {content &&  <p>{content}</p>}
      {contentComponent && contentComponent}
      {button && <button type="button" onClick={onClick}>{button}</button>}
    </div>
  )
}
