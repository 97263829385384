import React from 'react'

export const CheckIcon = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.453 8.5h-1.099a.746.746 0 00-.607.312l-3.684 5.11-1.669-2.317a.75.75 0 00-.607-.311h-1.1c-.152 0-.24.173-.152.297l2.92 4.05a.745.745 0 001.213 0l4.936-6.843a.187.187 0 00-.15-.298z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M12 3a9 9 0 10.001 18.001A9 9 0 0012 3zm0 16.473A7.475 7.475 0 014.527 12 7.474 7.474 0 0112 4.527 7.475 7.475 0 0119.473 12 7.475 7.475 0 0112 19.473z"
        fill="url(#prefix__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={12.07}
          y1={8.5}
          x2={12.07}
          y2={15.953}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D96FF" />
          <stop offset={1} stopColor="#5E46FF" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={12}
          y1={3}
          x2={12}
          y2={21}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D96FF" />
          <stop offset={1} stopColor="#5E46FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
