function Star({ activo = false, ...props}) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.224 8.451L16.03 7.356 12.813.528a.924.924 0 00-.41-.43c-.448-.232-.992-.039-1.216.43L7.97 7.356.776 8.451a.891.891 0 00-.519.276.975.975 0 00-.257.676.973.973 0 00.274.668l5.206 5.315-1.23 7.504a.99.99 0 00.053.519c.064.164.17.306.307.41a.872.872 0 00.955.071L12 20.347l6.435 3.543c.176.098.38.13.576.095.493-.089.824-.578.74-1.095l-1.23-7.504 5.205-5.315a.965.965 0 00.263-.543c.077-.519-.269-1-.765-1.077z"
        fill={!activo ? '#E9E9E9' : '#FDD162'}
      />
    </svg>
  )
}

export default Star