export const handleError = (error) => {
  console.error(error);
}

export const handleMesssageError = (error) => {
  if (error.response) {
    const errorMsg = error.response.data.error;
    if (typeof errorMsg === 'object') {
      const keys = Object.values(errorMsg);
      return keys[0][0]
    } else {
      return errorMsg;
    }
  }
}