import React from 'react'
import iconDivision from "../assets/icons/division.svg"
import iconChevron from "../assets/icons/chevron.svg"
import { useStateValue } from '../redux/StateProvider'
import { addChat } from '../db/dbFirebase'
import { callDispatch, fnSetStorage } from "../redux/functions"
import { actionTypes } from "../redux/constants"
import { getURLImage } from '../db/initialDB'
import { handleError } from '../utils/handleError'
import { useMutation } from 'react-query'
import axiosInstance from '../axios'

export default function DivisionItem({ imagen = iconDivision, nombre, nombre_ingles, id }) {
  const [{ lang }, dispatch] = useStateValue()
  const chatMutation = useMutation(newChat => axiosInstance.post('/chat', newChat))

  const handleClick = async () => {
    callDispatch(dispatch, true, actionTypes.SET_LOADING)
    try {
      const value = await addChat({enespera: true, division_id: id});
      await chatMutation.mutateAsync({ firebaseId: value.id, division_id: id })
      fnSetStorage(dispatch, id, actionTypes.SET_DIVISION)
      fnSetStorage(dispatch, value.id, actionTypes.SET_CHAT)
      fnSetStorage(dispatch, 2, actionTypes.SET_STEP)
    } catch (error) {
      handleError(error);
    } finally {
      callDispatch(dispatch, false, actionTypes.SET_LOADING )
    }
  }

  return (
    <li onClick={handleClick}>
      <div className="ssachat__divisiones--content">
        <img src={getURLImage('division', imagen)} className="ssachat__divisiones--icon" alt={`División ${nombre}`}/>
        <p>{lang === 'es' ? nombre : nombre_ingles}</p>
      </div>
      <img src={iconChevron} className="ssachat__divisiones--chevron" alt="Icono derecha" />
    </li>
  )
}
