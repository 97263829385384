import React from 'react'
import { updateFinalizadoChat } from '../db/dbFirebase'
import { useAdd } from '../hooks/useApi'
import { actionTypes } from '../redux/constants'
import { callDispatch } from '../redux/functions'
import { useStateValue } from '../redux/StateProvider'

export const TerminarModal = () => {
  const [{ chatId }, dispatch] = useStateValue()

  const closeMutation = useAdd({ url: '/chat/close-chat', onSuccess: () =>  callDispatch(dispatch, false, actionTypes.SET_TERMINAR_MODAL)})

  const handleTerminar = async () => {
    try {
      await closeMutation.mutateAsync({ firebaseId: chatId })
      await updateFinalizadoChat(chatId)
      
      
    } catch (error) {
      console.error(error.message)
    }
  }

  const handleCancel = () => {
    callDispatch(dispatch, false, actionTypes.SET_TERMINAR_MODAL)
  }

  return (
    <div className="ssa__modal">
      <div className="ssa__modal--content">
        <h3>¿Quieres terminar el chat?</h3>
        <p>Al terminar el chat se cierra la conversación con el agente. </p>
        <div className="ssa__modal--actions">
          <button onClick={handleCancel} className="ssachat__button ssachat__button--secundario">Cancelar</button>
          <button onClick={handleTerminar} className={`ssachat__button ssachat__button--primario ${closeMutation.isLoading ? 'ssachat__button--loading' : ''}`}>Terminar</button>
        </div>
      </div>
    </div>
  )
}
