import { format } from 'date-fns'
import { es } from 'date-fns/locale'

export function formatHourFromDate(date) {
  return format(date, 'H:mm', {locale: es})
}

export function formatHour24FromHour(hours) {
  return parseInt(hours.substring(0, 2))
}

export function getDayWeek() {
  const dateParse = new Date();
  return parseInt(format(dateParse, 'i', {locale: es}))
}

export function chatActiveHorario(horario) {
  if (horario.status) {
    var today = new Date().getHours();
    if (today >= formatHour24FromHour(horario.hora_inicio) && today < formatHour24FromHour(horario.hora_fin)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}