import React from 'react'

export const SendIcon = ({ activo = false, ...props}) => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.61125 9.14787L3.96225 10.0899C3.8973 10.1007 3.83636 10.1285 3.78554 10.1703C3.73472 10.2122 3.69582 10.2667 3.67275 10.3284L1.725 15.5461C1.539 16.0261 2.04075 16.4836 2.50125 16.2534L16.0012 9.50337C16.0948 9.45668 16.1734 9.38487 16.2284 9.29598C16.2833 9.20709 16.3125 9.10464 16.3125 9.00012C16.3125 8.89561 16.2833 8.79316 16.2284 8.70427C16.1734 8.61538 16.0948 8.54356 16.0012 8.49687L2.50125 1.74687C2.04075 1.51662 1.539 1.97487 1.725 2.45412L3.6735 7.67187C3.69646 7.73368 3.73531 7.78835 3.78614 7.83035C3.83697 7.87236 3.89797 7.90022 3.963 7.91112L9.612 8.85237C9.64676 8.85846 9.67827 8.87662 9.70097 8.90364C9.72367 8.93067 9.73612 8.96483 9.73612 9.00012C9.73612 9.03542 9.72367 9.06958 9.70097 9.09661C9.67827 9.12363 9.64676 9.14178 9.612 9.14787H9.61125Z" 
      fill={!activo ? '#898989' : '#5E46FF'}/>
    </svg>
  )
}
