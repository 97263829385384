import React, { useEffect, useState } from 'react'
import axiosInstance from '../axios';
import { Calificacion } from '../Components/Calificacion';
import Message from "../Components/Message";
import { getChatRoom, getChatsEnespera, getMessagesRoom } from '../db/dbFirebase';
import { actionTypes } from '../redux/constants';
import { callDispatch, fnSetStorage } from '../redux/functions';
import { useStateValue } from '../redux/StateProvider';
import ChatEnespera from './ChatEnespera';
import { config as configGlobal } from '../config'
import { textLang } from '../utils/lang';
import { Complete } from '../Components/Complete';

export default function Chat() {
  const [{chatId, transferir, config, info, lang}, dispatch] = useStateValue()
  const [chatRoom, setChatRoom] = useState(null)
  const [chatMessages, setChatMessages] = useState([])
  const [enespera, setEnespera] = useState(0)
  
  useEffect(() => {
    if (chatId === null) {
      dispatch({
        type: actionTypes.SET_STEP,
        payload: 1
      })
    }

    if (chatId) {
      const transGlobal = transferir
      getChatRoom(chatId)
      .onSnapshot(snapshot => {
        const { termino, solicitar, enespera, transferir } = snapshot.data()
        if (termino) {
          if (config?.calificacion) {
            localStorage.setItem(actionTypes.SET_CERRADO, 1)
          } else {
            fnSetStorage(dispatch, 1, actionTypes.SET_CERRADO)
          }
        }

        if (info === null || (transGlobal && !transferir)) {
          axiosInstance.post(`${configGlobal.api.chat}/show-info`, { firebaseId: chatId})
          .then(({data}) => {
            callDispatch(dispatch, data.data, actionTypes.SET_INFO)
          })
          .catch(e => console.error('Ha ocurrido un error'))
        }

        callDispatch(dispatch, termino, actionTypes.SET_FINALIZO)
        callDispatch(dispatch, enespera, actionTypes.SET_ENESPERA)
        callDispatch(dispatch, solicitar, actionTypes.SET_SOLICITAR)
        callDispatch(dispatch, transferir, actionTypes.SET_TRANSFERIR)
        setChatRoom(snapshot.data())
      })

      getMessagesRoom(chatId)
      .onSnapshot(snapshot => setChatMessages(snapshot.docs.map(doc => doc.data())))

      getChatsEnespera()
      .then(snapshot => {
        setEnespera(snapshot.size)
      })
    }
  }, [chatId, config?.calificacion, dispatch, info, transferir])

  if (chatRoom?.termino && config?.calificacion) {
    return <Calificacion chatId={chatId}/>
  }

  if (chatRoom && !chatRoom?.enespera) {
    return (
      <>
        {chatMessages.map((item, index) => item.solicitar === 0 ? (
          <Message key={index} {...item}/>
        ) : item.solicitar === 2 ? (
          <Complete 
            title={textLang(lang, 'datos_recibidos')}
            content={textLang(lang, 'datos_recibidos_content')}
          />
        ) : null)}
        {chatRoom.escribir_admin && <Message escribiendo/> }
      </>
    )
  } else {
    return <ChatEnespera enespera={enespera}/>
  }


  
}
