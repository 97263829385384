import { saveStorage } from "../db/initialDB"

export const fnSetStorage = (dispatch, value, actionType) => {
  callDispatch(dispatch, value, actionType)

  saveStorage(actionType, value)
}

export const callDispatch = (dispatch, value, actionType) => {
  dispatch({
    type: actionType,
    payload: value
  })
}