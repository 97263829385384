import React from 'react'

export const ContactoIcon = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.064 3.75H8.292a2.23 2.23 0 00-1.581.659c-.42.422-.655.994-.655 1.591v3.375H3.819v2.25h2.237v2.25H3.819v2.25h2.237v2.25H3.819v2.25h2.237V24c0 .597.235 1.169.655 1.591.419.422.988.659 1.58.659h16.773c.296 0 .581-.119.79-.33.21-.21.328-.497.328-.795V4.875c0-.298-.118-.585-.327-.795a1.115 1.115 0 00-.791-.33zm-8.945 3.374c1.843 0 3.354 1.52 3.354 3.375a3.4 3.4 0 01-.986 2.383c-.628.633-1.48.99-2.368.993-1.842 0-3.354-1.522-3.354-3.376 0-1.855 1.512-3.375 3.354-3.375zm6.709 14.626H9.41v-.844c0-2.496 3.025-5.062 6.709-5.062 3.684 0 6.709 2.566 6.709 5.062v.844z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={15.001}
          y1={3.75}
          x2={15.001}
          y2={26.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D96FF" />
          <stop offset={1} stopColor="#5E46FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
