import { useEffect, useRef, useState } from 'react'
import './styles/styles.scss'
import Chat from './pages/Chat'
import ChatDivision from './pages/ChatDivision'
import { useStateValue } from './redux/StateProvider'
import { addChat, addMessage, addNotificacion, updateEscritura } from './db/dbFirebase'
import Loading from './Components/Loading'
import { ChatHeader } from './Components/ChatHeader'
import axiosInstance from './axios'
import { callDispatch, fnSetStorage } from './redux/functions'
import { actionTypes } from './redux/constants'
import { clearStorage } from './utils/clearStorage'
import { ChatContacto } from './Components/ChatContacto'
import { chatActiveHorario, getDayWeek } from './utils/date'
import { ChatFueraHorario } from './pages/ChatFueraHorario'
import { textLang } from './utils/lang'
import { SendIcon } from './assets/icons/SendIcon'
import { useMutation } from 'react-query'
import { handleError } from './utils/handleError'
import { Alert } from './Components/Alert'
import { TerminarModal } from './Components/TerminarModal'
import { AttachIcon } from './assets/icons/AttachIcon'
import { useAdd } from './hooks/useApi'

export default function App() {
  const [active, setActive] = useState()
  const [loadingImage, setLoadingImage] = useState(false)
  const [{modalTerminar, info, loading, transferir, lang, horario, solicitar, step, chatId, enespera, config, finalizo, cerrado}, dispatch] = useStateValue()
  const [textarea, setTextarea] = useState('')
  const messagesEndRef = useRef(null)
  const chatMutation = useMutation(newChat => axiosInstance.post('/chat', newChat))
  const imageMutation = useAdd({url: '/chat-archivo', image: true, onSuccess: () => setLoadingImage(false) })

  const closeChat = e => {
    e.stopPropagation()
    setActive(false)
  }

  const openChat = () => {
    setActive(true)
    if (chatId && !enespera) {
      scrollToBottom()
    }
  }

  const handleTerminar = () => {
    callDispatch(dispatch, true, actionTypes.SET_TERMINAR_MODAL)
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }


  useEffect(() => {
    if (!config) {
      axiosInstance.get('/configuracion/1')
      .then(({data}) => {
        callDispatch(dispatch, data, actionTypes.SET_CONFIG)

        const diaSemana = getDayWeek()
        const horarios = data.horario
        let horarioActive = chatActiveHorario(horarios[diaSemana - 1])

        if (data.conectados === 0) horarioActive = false

        callDispatch(dispatch, horarioActive, actionTypes.SET_HORARIO)
      });
    }

    if (cerrado === 1) {
      let interval = setTimeout(() => {
        setActive(false);
        clearStorage(dispatch);
      }, 3000);

      return () => clearTimeout(interval);
    }
    
    
  }, [cerrado, config, dispatch])

  const newChatAtencionGeneral = async () => {
    callDispatch(dispatch, true, actionTypes.SET_LOADING)
    try {
      const value = await addChat({enespera: true, division_id: 8});
      await chatMutation.mutateAsync({ firebaseId: value.id, division_id: 8 })
      fnSetStorage(dispatch, 8, actionTypes.SET_DIVISION)
      fnSetStorage(dispatch, value.id, actionTypes.SET_CHAT)
      fnSetStorage(dispatch, 2, actionTypes.SET_STEP)

      addMessage({ chatId: value.id, message: textarea })
      .catch(error => console.error(error))
      .finally(() => {
        setTextarea('')
      })
    } catch (error) {
      handleError(error);
    } finally {
      callDispatch(dispatch, false, actionTypes.SET_LOADING )
    }
    
  }

  const handleClickImage = () => {
    inputFile.current.click();
  }

  const handleOnChangeFile = async event => {
    const file = event.target.files[0]
    if (file === null || file === undefined) return 
    const size = file.size
    if (size > 10000000) {
      alert('El archivo es muy pesado. Máximo 10MB')
      return;
    }
    try {
      const formData = new FormData();
      formData.append('archivo', file)
      formData.append('firebaseId', chatId)
      const { extension, archivo, nombre, peso } = (await imageMutation.mutateAsync(formData)).data
      const pesoMB = (peso / (1024*1024)).toFixed(2);
      if (['pdf', 'doc', 'docx', 'xlsx', 'csv', 'xls', 'pptx'].includes(extension)) {
        addMessage({ chatId: chatId, message: `${nombre} (${pesoMB}mb)`, isPDF: true, url: archivo})
      } else {
        addMessage({ chatId: chatId, message: null, isImage: true, url: archivo })
      }
    } catch (error) {
      //
    }
  }
 
  const handleClick = () => {
    if (chatId) {
      if (textarea !== '') {
        addMessage({ chatId, message: textarea })
        .catch(error => console.error(error))
        .finally(() => {
          setTextarea('')
          scrollToBottom()
          addNotificacion({ admin_id: info.id })
        })
      }
    } else {
      newChatAtencionGeneral()
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleClick()
    }
  }

  const handleOnFocus = async () => {
    if (chatId) {
      try {
        await updateEscritura(chatId, true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleOnBlur = async () => {
    if (chatId) {
      try {
        await updateEscritura(chatId, false)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const inputFile = useRef(null)

  return (
    <div className={`ssachat ${active ? 'active' : ''} ${finalizo ? 'finalizo' : ''} ${chatId && active && !enespera && !finalizo ? 'escribir' : ''}`}>
      {modalTerminar && <TerminarModal />}
      <ChatHeader loadingImage={loadingImage} config={config} onClick={openChat} onClickClose={closeChat}/>
      <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/chat.png'})`
      }}
       className="ssachat__body">
        {transferir && <Alert message="Tu chat esta siendo transferido, un momento por favor..." fixed/>}
        {loading && <Loading />}
        {horario ? (
          <>
            {step === 1 && !loading && <ChatDivision config={config} />}
            {step === 2 && !loading && (
              <>
                <Chat />
                <div ref={messagesEndRef} />
              </>
            )}
          </>
        ) : (
          <ChatFueraHorario config={config} />
        )}
        
      </div>
      {horario && (step === 1 || step === 2) && (
        <div className="ssachat__footer">
          {!enespera && (
            <div className="ssachat__footer--content">
              <textarea
                placeholder={textLang(lang, 'placeholder_message')}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                onKeyDown={handleKeyDown}
                value={textarea}
                onChange={e => setTextarea(e.target.value)}></textarea>
              <div className='ssachat__footer--buttons'>
                <button className='button_attach' type='button' onClick={handleClickImage}>
                  <AttachIcon />
                  <input type="file" onChange={handleOnChangeFile} ref={inputFile} style={{display: 'none'}} accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .doc,.docx,.pptx, application/vnd.ms-excel,.csv"/>
                </button>
                <button disabled={textarea === ''} type="button" onClick={handleClick}>
                  <SendIcon activo={textarea !== ''}/>
                </button>
              </div>
          </div>
          )}
          {step === 2 && (
          <div className="ssachat__footer--terminar">
            <button onClick={handleTerminar} type="button">{textLang(lang, 'button_terminar')}</button>
          </div>
          )}
        </div>
      )}
      {active && solicitar && <ChatContacto />}
    </div>
  )
}

