export const config = {
  IMAGES: '' || process.env.REACT_APP_URL_IMAGES
}

export const saveStorage = (key, value) => {
  window.localStorage.setItem(key, value)
}

export const getStorage = (key) => {
  return window.localStorage.getItem(key)
  
}

export const deleteStorage = (key) => {
  window.localStorage.removeItem(key)
}

export const getURLImage = (folder, imagen) => {
  return `${config.IMAGES}/${folder}/${imagen}`;
}