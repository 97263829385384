import React from 'react'
import { useStateValue } from '../redux/StateProvider'
import {config as configuracion} from '../config'
import cancelarIcon from '../assets/icons/cancelar_ciculo.svg'
import { updateSolicitar, addMessage } from '../db/dbFirebase'
import { callDispatch } from '../redux/functions'
import { actionTypes } from '../redux/constants'
import { useAdd } from '../hooks/useApi'
import { textLang } from '../utils/lang'
import { ContactoIcon } from '../assets/icons/ContactoIcon'
import { useForm } from 'react-hook-form'

export const ChatContacto = () => {
  const [{division, chatId, lang, config}, dispatch] = useStateValue()
  const { register, reset, setValue, formState: { errors }, handleSubmit } = useForm();

  const contactoMutation = useAdd({url: `${configuracion.api.contacto}/save-data`, onSuccess: () => {
    reset()
    handleClickClose(2)
  }})

  const onSubmit = (data) => contactoMutation.mutate({...data, division_id: division, firebaseId: chatId})

  const telefono = register("telefono", { required: config?.formulario_telefono })

  const handleClickClose = async (solicitar = 2) => {
    try {
      await updateSolicitar(chatId, false)
      await addMessage({chatId, solicitar})
      callDispatch(dispatch, false, actionTypes.SET_SOLICITAR)
    } catch (error) {
      console.error(error)
    }
    
  }

  const handleChange = ({target}) => {
    const {name, value} = target

    const regMatch = name === 'telefono' ? /^[0-9]*$/.test(value)  : /^[a-zA-Z]*$/.test(value)
    if (regMatch) {
      setValue(name, value, { shouldValidate: true })
    } else {
      setValue(name, value.substring(0, value.length - 1), { shouldValidate: true })
    }
  }

  return (
    <div className="ssachat__chatcontacto">
      <div className="ssachat__chatcontacto__content">
        <div className="ssachat__chatcontacto__body">
          <div className="ssachat__chatcontacto__close" onClick={() => handleClickClose(3)}>
            <img src={cancelarIcon} alt="Cerrar icon"/>
          </div>
          <div className="ssachat__head">
            <ContactoIcon />
            <p>Datos de contacto</p>
          </div>
          <div className="ssa__formulario">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="ssa__field">
                <label>
                  {textLang(lang, 'nombre')}
                  {config?.formulario_nombre ? '*' : ''}
                </label>
                <input 
                  className={`ssa__input ${errors.nombre ? 'ssa__input--error' : ''}`}
                  type="text"  
                  placeholder={textLang(lang, 'placeholder_nombre')} 
                  {...register("nombre", { required: config?.formulario_nombre })}
                  />
              </div>
              <div className="ssa__field">
                <label>
                  {textLang(lang, 'email')}
                  {config?.formulario_correo ? '*' : ''}
                </label>
                <input 
                  className={`ssa__input ${errors.correo ? 'ssa__input--error' : ''}`}
                  type="email" 
                  placeholder={textLang(lang, 'placeholder_email')}
                  {...register("correo", { required: config?.formulario_correo, pattern: /^\S+@\S+$/i })}
                />
                {errors.correo && <span style={{color: 'red', fontSize: '12px'}}>{textLang(lang, 'error_correo')}</span>}
              </div>
              <div className="ssa__field">
                <label>
                  {textLang(lang, 'telefono')}
                  {config?.formulario_telefono ? '*' : ''}
                </label>
                <input 
                  className={`ssa__input ${errors.telefono ? 'ssa__input--error' : ''}`}
                  type="text" 
                  placeholder={textLang(lang, 'placeholder_telefono')}
                  onChange={(e) => {
                    handleChange(e); // your method
                  }}
                  name="telefono"
                  onBlur={telefono.onBlur}
                  ref={telefono.ref}
                  />
                {errors.telefono && <span style={{color: 'red', fontSize: '12px'}}>{textLang(lang, 'error_telefono')}</span>}
              </div>
              {contactoMutation.isError && <span style={{color: 'red', fontSize: '12px'}}>{textLang(lang, 'error_completa')}</span>}
              <p className="ssa__terminos">{textLang(lang, 'campos')}</p>
              <p className="ssa__terminos ssa__terminos--notop">{textLang(lang, 'terminos')}</p>
              <p className="ssa__terminos ssa__terminos--notop">
                {textLang(lang, 'consultar')} <a className="ssacolor-morado" href="https://sanfersaludanimal.com/assets/images/aviso_privacidad.doc.pdf" target="_blank" rel="noreferrer">{textLang(lang, 'aviso')}</a>.
              </p>
              <div className="ssa__field ssa__field--button">
                <button disabled={contactoMutation.isLoading} type="submit">{textLang(lang, 'button_enviar')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
