import db from "../firebase"
import firebase from 'firebase/app'

const TABLE_CHATS = 'chats';
const TABLE_NOTIFICACIONES = 'notificaciones';

export const addChat = ({enespera = true, division_id = null}) => {

  return db.collection(TABLE_CHATS).add({
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    enespera: enespera,
    escribir_admin: false,
    escribir_user: false,
    termino: false,
    solicitar: false,
    transferir: false,
    division_id: division_id
  })
}

export const addMessage = ({ chatId, message = "", adminImagen = null, isImage = false, isPDF = false, url = null, solicitar = 0 }) => {
  return db.collection(TABLE_CHATS).doc(chatId)
    .collection('messages').add({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      user: true,
      message: message,
      isImage: isImage,
      isPDF: isPDF,
      url: url,
      solicitar: solicitar,
      adminImagen: adminImagen
    })
}

export const addNotificacion = async ({ admin_id  }) => {
  const tabledoc = db.collection(TABLE_NOTIFICACIONES).doc(`${admin_id}`)
  const doc = await tabledoc.get()

  if (!doc.exists) {
    tabledoc.set({
      numero: 1
    })
  } else {
    const numero = doc.data()
    tabledoc.update({
      numero: numero.numero + 1
    })
  }

}

export const getChatRoom = (chatId) => {
  return db.collection(TABLE_CHATS).doc(chatId)
}

export const getMessagesRoom = (chatId) => {
  return db.collection(TABLE_CHATS).doc(chatId)
  .collection('messages')
  .orderBy('timestamp', 'asc')
}

export const getChatsEnespera = () => {
  return db.collection(TABLE_CHATS).where("enespera", "==", true).get()
}

export const updateEscritura = (id, flag) => {
  if (id) {
    return db.collection(TABLE_CHATS).doc(id).update({
      escribir_user: flag
    })
  }
}

export const updateSolicitar = (id, flag) => {
  return db.collection(TABLE_CHATS).doc(id).update({
    solicitar: flag
  })
}

export const updateFinalizadoChat = (id) => {
  return db.collection(TABLE_CHATS).doc(id).update({
    enespera: false,
    solicitar: false,
    transferir: false,
    termino: true
  })
}