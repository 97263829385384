import React from 'react'

export const CentroAtencionIcon = (props) => {
  const { color = 'white', ...rest } = props
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      >
      <path d="M4 3C2.9 3 2 3.9 2 5V17L5 14H14C15.1 14 16 13.1 16 12V5C16 3.9 15.1 3 14 3H4ZM18 8V12C18 14.206 16.206 16 14 16H8V17C8 18.1 8.9 19 10 19H19L22 22V10C22 8.9 21.1 8 20 8H18Z" 
      fill={color}/>
    </svg>
  )
}
