import React from 'react'
import caraIcon from '../assets/icons/cara.svg'
import { getURLImage } from '../db/initialDB'
import { formatHourFromDate } from '../utils/date'
import pdfIcon from '../assets/icons/pdf.svg'
import xlsIcon from '../assets/icons/xls.svg'
import xlsxIcon from '../assets/icons/xlsx.svg'
import docIcon from '../assets/icons/doc.svg'
import docxIcon from '../assets/icons/docx.svg'
import csvIcon from '../assets/icons/csv.svg'
import pptxIcon from '../assets/icons/pptx.svg'
import { config } from '../config'
import { urlify } from '../utils/chat'

export default function Message({ message, timestamp, user = false, url, isImage, isPDF, escribiendo = false, adminImagen }) {
  return (
    <div className={`ssamessage ${user ? 'ssamessage--right' : ''}`}>
      <MessageImagen user={user} imagen={adminImagen}/>
      {escribiendo ? (
        <div className="ssamessage__escribiendo">
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className={`ssamessage__text ${isImage ? 'ssamessage__text--image' : ''} ${isPDF ? 'ssamessage__text--pdf' : ''}`}>
          {isImage ? (
            <a href={getURLImage('chat', url)} target="_blank" rel='noreferrer'><img src={getURLImage('chat', url)} alt="Imagen chat"/></a>
          ) : isPDF ? <MessageFilePDF message={message} url={url}/>
          :<p dangerouslySetInnerHTML={{__html: urlify(message)}}></p>}
          {timestamp && <p className="ssamessage__text--hora">{formatHourFromDate(timestamp.toDate())}</p>}
        </div>
      )}
    </div>
  )
}

const MessageImagen = ({imagen = null, user}) => {
  if (imagen && !user) {
    return (
      <div className="ssamessage__image ssachatuser-image">
        <img src={getURLImage('admin', imagen)} alt="Icono mensaje"/>
      </div>
    )
  } else {
    return ( 
      <div className="ssamessage__image">
        <img src={caraIcon} alt="Icono mensaje"/>
      </div>
    )
  }
}

const MessageFilePDF = ({ message, url }) => {
  const handleClick = () => {
    window.open(`${config.URL_UPLOADS}/chat/${url}`, '_blank');
  }

  const getIcon = (file) => {
    const ext = file.split('.').pop()

    if (ext === 'xls') {
      return xlsIcon
    }
    if (ext === 'xlsx') {
      return xlsxIcon
    }
    if (ext === 'csv') {
      return csvIcon
    }
    if (ext === 'doc') {
      return docIcon
    }
    if (ext === 'docx') {
      return docxIcon
    }
    if (ext === 'pptx') {
      return pptxIcon
    }

    return pdfIcon
  }

  return (
    <div className="ssachat__pointer" onClick={handleClick}>
      <img src={getIcon(url)} alt="Icono PDF" />
      <p>{message}</p>
    </div>
  )
}